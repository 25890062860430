<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入关键词" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.status" placeholder="请选择状态" size="small">
          <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.store" clearable placeholder="请选择门店" size="small">
          <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker size="small"
                        v-model="dateRange"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="order_sn"
          label="订单号"
          min-width="130">
      </el-table-column>
      <el-table-column
          prop="goods"
          label="商品"
          width="450">
        <template slot-scope="scope">
          <table width="100%" class="gdtable">
            <tr v-for="(item,index) in scope.row.goods" :key="index" style="background-color: unset;border-bottom: 1px solid #f5f5f5">
              <td style="padding-bottom: 10px;width: 50px;"><img :src="item.goods_img" style="width: 40px;height: 40px;"></td>
              <td style="text-align: left;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{item.goods_name}} <span v-if="item.specs_name">/ {{item.specs_name}}</span></td>
              <td style="width: 200px; text-align: right">￥{{item.price}} x{{item.quantity}}<br><span v-if="item.hourse_name">发货仓库：{{item.hourse_name}}</span></td>
            </tr>
          </table>
        </template>
      </el-table-column>
      <el-table-column
          prop="store_str"
          label="下单门店"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="user_id_str"
          label="下单人"
          align="center"
          width="120">
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="address"-->
<!--          label="收货地址"-->
<!--          align="left"-->
<!--          width="190">-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{scope.row.address.name}} {{scope.row.address.mobile}}<br>-->
<!--            {{scope.row.address.province_name}}{{scope.row.address.city_name}}{{scope.row.address.district_name}}{{scope.row.address.address}}-->
<!--            <el-button type="text" id="copyId" @click="copyTxt(scope.row.address.name + '，' + scope.row.address.mobile + '，' + scope.row.address.province_name+scope.row.address.city_name+scope.row.address.district_name+scope.row.address.address)" size="small">复制</el-button>-->
<!--          </span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
          prop="goods_amount"
          label="商品价格"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="balance_amount"
          label="预存款抵扣"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="ump_amount"
          label="优惠价格"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="amount"
          label="实付款"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="订单状态"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="pay_pic"
          label="支付凭证"
          align="center"
          width="140">
        <template slot-scope="scope">
          <viewer :images="scope.row.pay_pic">
            <img v-for="(item,index) in scope.row.pay_pic" :src="item" :key="index" width="50" height="50">
          </viewer>
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="下单时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.status==1">
            <el-button type="text" size="small" @click="dialogPayVisible=true; itemInfo=JSON.parse(JSON.stringify(scope.row))">确认支付</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="确认支付"
        :visible.sync="dialogPayVisible"
        width="30%">
      <span>用户支付凭证：</span>
      <div class="mg-20">
        <viewer :images="itemInfo.pay_pic">
          <img v-for="(item,index) in itemInfo.pay_pic" :src="item" :key="index" width="100" height="100">
        </viewer>
      </div>
<!--      <span>上传确认凭证：</span>-->
<!--      <div class="mg-20">-->
<!--        <SelectImgs :selectNum="3" :selectData="pay.imgs" :selectFunc="imgChoose" :deleteFunc="deleteFunc"></SelectImgs>-->
<!--        <span style="color: #ccc;">最多3张图</span>-->
<!--      </div>-->
      <div style="clear: both"></div>
      <span>备注：</span>
      <div class="mg-20">
        <el-input type="textarea" v-model="pay.desc" placeholder="请输入备注"></el-input>
      </div>
      <div style="clear: both"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPayVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="surePay" size="small">确认支付</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
export default {
  components: {
    // SelectImgs
  },
  data() {
    return {
      info: false,
      dialogPayVisible: false,
      dateRange: ['',''],
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: 2,
        store: ''
      },
      tableData: [],
      statusList: [
        {id:2,title:'待确认'},
        {id:20,title:'已确认'},
      ],
      storeList: [],
      itemInfo: false,
      setInfo: false,
      pay: {
        imgs: [],
        desc: ''
      }
    };
  },
  created() {
    this.getSetInfo()
    this.getStoreList()
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    imgChoose(item) {
      var _this = this
      item.forEach(function (pic) {
        if(_this.pay.imgs.length < 3) {
          _this.pay.imgs.push(pic.pic)
        }
      })
    },
    deleteFunc(idx) {
      var imgs = JSON.parse(JSON.stringify(this.pay.imgs))
      imgs.splice(idx,1)
      this.pay.imgs = imgs
    },
    getSetInfo() {
      this.$api.merchant.sysSet(res => {
        if (res.errcode == 0) {
          this.setInfo = res.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getStoreList() {
      this.$api.merchant.storeIndex({page_size: 1000}, res => {
        if (res.errcode == 0) {
          this.storeList = res.data.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
        store: that.search.store,
        start_time: this.dateRange[0],
        end_time: this.dateRange[1],
      }
      this.$api.order.orderIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    surePay() {
      var params = {
        id: this.itemInfo.id,
        // pics: this.pay.imgs,
        desc: this.pay.desc
      }
      // if(params.pics.length < 1) {
      //   this.fail('请上传确认图')
      //   return false
      // }
      if(params.desc == '') {
        this.fail('请填写备注')
        return false
      }
      this.$api.order.orderSurepay(params, res => {
        if (res.errcode == 0) {
          this.dialogPayVisible = false
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
.gdtable tr:last-child {
  border-bottom: unset !important;
}
</style>
